import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoDiamondOutline } from 'react-icons/io5';
import { useMeuPlano } from '../../hooks/useMeuPlano';
import { HeaderScreen } from '../HeaderScreen';
import { ContainerPlanosDisponiveis, ContentCard } from './styles';
import { DadosFaturamento } from './components/DadosFaturamento';
import { DadosConcluir } from '../DadosConcluir';
import { DadosCliente } from '../DadosCliente';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { getYear } from 'date-fns';
import confetti from 'canvas-confetti';
import { formatCurrencyAsText } from '~/utils/functions';
import { TiArrowDownThick } from 'react-icons/ti';

type Steps = 'DADOS' | 'FATURAMENTO' | 'CONCLUIR' | 'FINALIZADO';
const stepsOrder: Steps[] = ['DADOS', 'FATURAMENTO', 'CONCLUIR', 'FINALIZADO'];

export const AvailablePlans: React.FC = () => {
  const {
    screen,
    tipoStatus,
    codSeqPlano,
    planoPagamento,
    handleScreen,
    methodFaturamento,
    formClienteContratacao,
    formCard,
    selectedMethod,
    setChaveBoleto,
    setBoletoURL,
    setTipoMudancaPlano,
    isDemonstracao,
    contratacaoCliente,
    setValSelectedPlan,
    creditCard,
    refetchCustomer,
    semanaDoConsumidorAtivo,
    setDemonstracaoOff,
  } = useMeuPlano();

  const { getValuesClienteContratacao, triggerClienteContratacao } =
    formClienteContratacao;
  const { triggerCard, getValuesCard } = formCard;
  const [planoSelecionado, setPlanoSelecionado] = useState('');
  const [step, setStep] = useState<Steps>('DADOS');
  const [showLoading, setShowLoading] = useState(false);
  // const [planos, setPlanos] = useState<Plano[]>([]);

  const planos = useMemo(
    () => [
      {
        plano: 'Basic',
        content: [
          'FUNCIONALIDADES ESSENCIAIS',
          'PDV ONLINE',
          'SUPORTE POR CHAT',
        ],
        val_plano_mensal: 89.9,
        val_plano_anual: 899,
        economize: 0,
        cod_seq_plano: 1,
        observationAnual: 'Economia de 2 meses',
      },
      {
        plano: 'Pro',
        content: [
          'TUDO DO BASIC',
          'PDV ONLINE',
          'SUPORTE POR CHAT',
          'INTEGRAÇÃO IMPRESSORA DE ETIQUETAS E BALANÇA',
        ],
        val_plano_mensal: 129.9,
        val_plano_anual: 1299,
        economize: 0,
        cod_seq_plano: 2,
        observationAnual: 'Economia de 2 meses',
      },
      {
        plano: 'Premium',
        content: [
          'TUDO DO PRO',
          'PDV ONLINE',
          'SUPORTE POR CHAT',
          'INTEGRAÇÃO IMPRESSORA DE ETIQUETAS E BALANÇA',
          'RECEPÇÃO AUTOMÁTICA DE XML',
        ],
        val_plano_mensal: 175.9,
        val_plano_anual: 1759,
        economize: 0,
        cod_seq_plano: 3,
        observationAnual: 'Economia de 2 meses',
      },
    ],
    [],
  );

  const getTipoCiclo = useCallback(() => {
    switch (planoPagamento) {
      case 'ANUAL':
        return 6;
      case 'MENSAL':
        return 2;
      default:
        return 2;
    }
  }, [planoPagamento]);

  const handlePlanSelected = useCallback(
    (plan: string) => {
      if (tipoStatus.contratacaoTransitoria) {
        toast.warning(
          'Mudança de plano aguardando vigência. O novo plano poderá ser selecionado quando o contrato futuro entrar em vigor.',
        );
        return;
      }
      if (!tipoStatus.contratacaoAtiva) {
        toast.warning(
          'Mudança de plano indisponível. Apenas contratos ativos podem ser alterados.',
        );
        return;
      }
      const selectedPlan = planos.find((plano) => plano.plano === plan);

      if (!selectedPlan) {
        toast.warn('Não foi possível recuperar o plano selecionado');
        return;
      }

      if (
        !isDemonstracao &&
        contratacaoCliente?.tipo_ciclo === 6 &&
        getTipoCiclo() === 2
      ) {
        toast.warn(
          'Para alteração de plano anual para mensal, favor entrar em contato com o suporte.',
        );
        return;
      }

      let val_plano =
        planoPagamento === 'ANUAL'
          ? selectedPlan?.val_plano_anual
          : selectedPlan.val_plano_mensal;

      if (Number(selectedPlan?.cod_seq_plano) === 2)
        val_plano =
          planoPagamento === 'ANUAL' && semanaDoConsumidorAtivo ? 1039 : 1299;

      setValSelectedPlan(val_plano);

      setPlanoSelecionado(plan);
      handleScreen('PLANOS-DISPONIVEIS-DADOS');
    },
    [
      contratacaoCliente?.tipo_ciclo,
      getTipoCiclo,
      handleScreen,
      isDemonstracao,
      planoPagamento,
      planos,
      setValSelectedPlan,
      tipoStatus.contratacaoAtiva,
      tipoStatus.contratacaoTransitoria,
      semanaDoConsumidorAtivo,
    ],
  );

  const getStepIndex = (currentStep: Steps): number => {
    return stepsOrder.indexOf(currentStep);
  };

  const currentStepIndex = getStepIndex(step);

  const validaDadosCliente = useCallback(
    async (stp: string): Promise<boolean> => {
      if (stp === 'FATURAMENTO') {
        // Dispara a validação de todo o formulário de cliente
        const isValid = await triggerClienteContratacao();

        if (!isValid) {
          return false;
        }
      }
      return true;
    },
    [triggerClienteContratacao],
  );

  const validaDadosCartao = useCallback(
    async (stp: string): Promise<boolean> => {
      if (stp === 'CONCLUIR') {
        if (selectedMethod === 'cartao-credito') {
          const isValid = await triggerCard();

          if (!isValid) {
            return false;
          }
        }
      }
      return true;
    },
    [selectedMethod, triggerCard],
  );

  const getTipoFormaPagamento = useCallback(() => {
    switch (selectedMethod) {
      case 'cartao-credito':
        return 1;
      case 'last-credit-card':
        return 1;
      default:
        return 0; // boleto
    }
  }, [selectedMethod]);

  const congrats = () => {
    const count = 200;
    const defaults = {
      origin: { y: 0.7 },
    };

    const fire = (particleRatio: any, opts: any) => {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    };
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  /** Get Customer IP */
  const [customerIP, seCustomerIP] = useState('');

  useEffect(() => {
    fetch('https://api64.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => seCustomerIP(data.ip))
      .catch((error) => console.error(error));
  }, []);

  const getBillingData = useCallback(() => {
    const {
      des_nome,
      des_email,
      num_cpf_cnpj,
      num_telefone,
      num_cep,
      des_rua,
      des_numero,
      des_complemento,
      des_bairro,
      des_cidade,
    } = getValuesClienteContratacao();

    return {
      nome_pessoa: des_nome,
      num_cpf_cnpj,
      email: des_email,
      num_telefone,
      num_celular: num_telefone,
      des_logradouro: des_rua,
      num_endereco: des_numero,
      des_complemento,
      des_bairro,
      num_cep,
      num_ie: '',
      des_empresa_trab: '',
      cidade: des_cidade,
    };
  }, [getValuesClienteContratacao]);

  /** @TODO - Abstrair e refatorar */
  const handleStep = useCallback(
    async (tipo: 'next' | 'prev') => {
      try {
        const nextNext =
          tipo === 'next' ? currentStepIndex + 1 : currentStepIndex - 1;
        const stp = stepsOrder[nextNext];

        // Valida Dados do Cliente
        if (!(await validaDadosCliente(stp))) {
          return;
        }

        // Valida Faturamento
        if (!(await validaDadosCartao(stp))) {
          return;
        }

        const selectedPlan = planos.find(
          (plan) => plan.plano === planoSelecionado,
        );

        if (!selectedPlan) {
          toast.warn('Não foi possível recuperar o plano selecionado');
          return;
        }

        // const val_plano =
        //   planoPagamento === 'ANUAL'
        //     ? selectedPlan?.val_plano_anual
        //     : selectedPlan.val_plano_mensal;

        let operationType =
          selectedPlan.cod_seq_plano > codSeqPlano ? 'upgrade' : 'downgrade';

        if (isDemonstracao) {
          operationType = 'subscribe';
        }

        // Conclui contratacao via boleto
        if (stp === 'CONCLUIR') {
          if (selectedMethod === 'boleto') {
            setShowLoading(true);

            const tipo_forma_pagamento = getTipoFormaPagamento();

            setTipoMudancaPlano(operationType);

            const billingData = getBillingData();

            const payload: any = {
              cod_seq_plano: selectedPlan?.cod_seq_plano,
              tipo_ciclo: getTipoCiclo(),
              // val_plano: Number(val_plano),
              tipo_forma_pagamento,
              operationType,
              customerIP,
              billingData,
            };

            const response = await api.post('/meu-plano/subscription', payload);

            if (!response.data.success || !response.data.data) {
              toast.error('Falha na criação da assinatura.');
              return;
            }

            if (response.data.data.des_boleto_campo_identificacao)
              setChaveBoleto(response.data.data.des_boleto_campo_identificacao);
            if (response.data.data.des_boleto_url)
              setBoletoURL(response.data.data.des_boleto_url);

            if (operationType === 'downgrade') {
              toast.success('Seu plano foi atualizado com sucesso.');
              congrats();
              handleScreen('HOME');
              return;
            }

            setDemonstracaoOff();
            congrats();
            setStep(stp);
            return;
          }
        }

        // Conclui contratacao via cartão
        if (stp === 'FINALIZADO') {
          if (selectedMethod === 'cartao-credito') {
            setShowLoading(true);

            if (!selectedPlan) {
              toast.warn('Não foi possível recuperar o plano selecionado');
              return;
            }
            const tipo_forma_pagamento = getTipoFormaPagamento();

            setTipoMudancaPlano(operationType);

            const billingData = getBillingData();

            const payload: any = {
              cod_seq_plano: selectedPlan?.cod_seq_plano,
              tipo_ciclo: getTipoCiclo(),
              // val_plano: Number(val_plano),
              tipo_forma_pagamento,
              operationType,
              customerIP,
              billingData,
            };

            if (tipo_forma_pagamento === 1) {
              const creditCardData = getValuesCard();
              const clienteData = getValuesClienteContratacao();

              const { name, expiry, number, cvc } = creditCardData;

              const {
                des_nome,
                des_email,
                num_cpf_cnpj,
                num_cep,
                des_numero,
                des_complemento,
                num_telefone,
                // num_celular,
              } = clienteData;

              const expiryMonth = expiry.split('/')[0];
              const expiryYear = expiry.split('/')[1];
              const anoAtual = getYear(new Date());
              const preYear = String(anoAtual).substring(0, 2);

              payload.creditCard = {
                holderName: name,
                number,
                expiryMonth,
                expiryYear: `${preYear}${expiryYear}`,
                ccv: cvc,
              };
              payload.creditCardHolderInfo = {
                name: des_nome,
                email: des_email,
                cpfCnpj: num_cpf_cnpj,
                postalCode: num_cep,
                addressNumber: des_numero,
                addressComplement: des_complemento,
                phone: num_telefone,
                mobilePhone: num_telefone,
              };
            }

            await api.post('/meu-plano/subscription', payload);

            if (operationType === 'downgrade') {
              toast.success('Seu plano foi atualizado com sucesso.');
              setDemonstracaoOff();
              congrats();
              handleScreen('HOME');
              return;
            }
            setDemonstracaoOff();
            congrats();
          }
          if (selectedMethod === 'last-credit-card') {
            setShowLoading(true);

            if (!selectedPlan) {
              toast.warn('Não foi possível recuperar o plano selecionado');
              return;
            }
            const tipo_forma_pagamento = getTipoFormaPagamento();

            const billingData = getBillingData();

            setTipoMudancaPlano(operationType);

            const payload: any = {
              cod_seq_plano: selectedPlan?.cod_seq_plano,
              tipo_ciclo: getTipoCiclo(),
              // val_plano: Number(val_plano),
              tipo_forma_pagamento,
              operationType,
              customerIP,
              des_chave_token_cartao: creditCard?.des_chave_token_cartao,
              billingData,
            };

            await api.post('/meu-plano/subscription', payload);

            if (operationType === 'downgrade') {
              toast.success('Seu plano foi atualizado com sucesso.');
              setDemonstracaoOff();
              congrats();
              handleScreen('HOME');
              return;
            }
            setDemonstracaoOff();
            congrats();
          }
        }

        setStep(stp);
      } catch (error) {
        console.error(error);
      } finally {
        refetchCustomer();
        setShowLoading(false);
      }
    },
    [
      codSeqPlano,
      creditCard?.des_chave_token_cartao,
      currentStepIndex,
      customerIP,
      getBillingData,
      getTipoCiclo,
      getTipoFormaPagamento,
      getValuesCard,
      getValuesClienteContratacao,
      handleScreen,
      isDemonstracao,
      planoSelecionado,
      planos,
      refetchCustomer,
      selectedMethod,
      setBoletoURL,
      setChaveBoleto,
      setTipoMudancaPlano,
      validaDadosCartao,
      validaDadosCliente,
      setDemonstracaoOff,
    ],
  );

  const onBack = useCallback(() => {
    // handleMethodFaturamento(null);
    if (step === 'DADOS') {
      setPlanoSelecionado('');
      setStep('DADOS');
      handleScreen('PLANOS-DISPONIVEIS');
    } else {
      const stp = stepsOrder[currentStepIndex - 1];
      setStep(stp);
    }
  }, [currentStepIndex, handleScreen, step]);

  /** Descomente para buscar os planos diretamente do banco de dados */
  // useEffect(() => {
  //   (async () => {
  //     const response = await api.get('/meu-plano/available-plans');

  //     const { success, data } = response.data;

  //     if (success && data.length > 0) {
  //       const plans = data.map((plano: PlanoResponse) => {
  //         let content = [''];
  //         if (plano.cod_seq_plano === 1)
  //           content = [
  //             'PDV ONLINE',
  //             'EMISSÃO DE NFE E NFCE',
  //             'SUPORTE ATÉ 48H',
  //           ];
  //         if (plano.cod_seq_plano === 2)
  //           content = [
  //             'TUDO DO BASIC',
  //             'INTEGRAÇÃO COM PERIFÉRICOS',
  //             'RELATÓRIOS POR I.A.',
  //             'SUPORTE POR CHAT',
  //           ];
  //         if (plano.cod_seq_plano === 3)
  //           content = [
  //             'TUDO DO PRO',
  //             'RECEPÇÃO AUTOMÁTICA DE XML',
  //             'INTEGRAÇÃO COM PDV SYG',
  //             'SUPORTE PRIORITÁRIO',
  //           ];

  //         return {
  //           plano: plano.des_nome,
  //           content,
  //           val_plano_mensal: plano.val_plano_mensal,
  //           val_plano_anual: plano.val_plano_anual,
  //           economize: 0,
  //           cod_seq_plano: plano.cod_seq_plano,
  //         };
  //       });
  //       plans.sort(
  //         (a: PlanoResponse, b: PlanoResponse) =>
  //           a.cod_seq_plano - b.cod_seq_plano,
  //       );
  //       console.log(plans);
  //       setPlanos(plans);
  //       return;
  //     }

  //     toast.warn('Planos não encontrados');
  //     handleScreen('HOME');
  //   })();
  // }, [handleScreen]);

  if (screen === 'HOME') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <IoDiamondOutline size={130} color="#FFC107" />
        <p
          style={{
            textAlign: 'center',
            fontSize: '1.25rem',
            lineHeight: '1.875rem',
          }}
        >
          Planos disponíveis
        </p>
      </div>
    );
  }

  return (
    <ContainerPlanosDisponiveis>
      <HeaderScreen
        showButtonVoltar={screen === 'PLANOS-DISPONIVEIS'}
        showMensalAnual={screen === 'PLANOS-DISPONIVEIS'}
        showTrilha={screen === 'PLANOS-DISPONIVEIS-DADOS'}
        stepTrilha={currentStepIndex}
      />
      {screen === 'PLANOS-DISPONIVEIS' && (
        <ContentCard
          planoPagamento={
            contratacaoCliente?.tipo_ciclo === 6 ? 'ANUAL' : 'MENSAL'
          }
        >
          {planos.map((plan) => {
            const isPlanoContratado =
              plan.cod_seq_plano === codSeqPlano &&
              (contratacaoCliente?.tipo_ciclo ===
                (planoPagamento === 'ANUAL' ? 6 : 2) ||
                (planoPagamento !== 'ANUAL' &&
                  contratacaoCliente?.tipo_ciclo === 0));

            const ofertaAtivaPlanPro =
              semanaDoConsumidorAtivo &&
              plan?.plano?.toUpperCase() === 'PRO' &&
              planoPagamento === 'ANUAL';

            let descricaoPlano: string;

            if (planoPagamento === 'ANUAL') {
              if (ofertaAtivaPlanPro) {
                descricaoPlano = 'no plano anual *no primeiro ano';
              } else {
                descricaoPlano = 'no plano anual';
              }
            } else {
              descricaoPlano = 'mês';
            }

            return (
              <div
                key={plan.plano}
                className="card-content"
                id={ofertaAtivaPlanPro ? 'card-consumidor' : undefined}
              >
                {/* Cabeçalho com o nome do plano */}
                <h2 className="plan-title">{plan.plano}</h2>

                {ofertaAtivaPlanPro && planoPagamento === 'ANUAL' && (
                  <strong
                    style={{
                      color: 'white',
                      marginBottom: '-0.9375rem',
                      marginTop: '-0.625rem',
                      backgroundColor: '#22b14c',
                      padding: '0.125rem',
                      borderRadius: '0.2188rem',
                      textAlign: 'center',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    SEMANA DO CONSUMIDOR (20%
                    <TiArrowDownThick size={18} />)
                  </strong>
                )}

                {/* Preço */}
                <div className="plan-price">
                  <strong>
                    {ofertaAtivaPlanPro ? '' : 'R$ '}
                    {planoPagamento === 'ANUAL' ? (
                      ofertaAtivaPlanPro ? (
                        <>
                          <span className="outer">
                            <span className="inner">
                              R$ {formatCurrencyAsText(plan.val_plano_anual)}
                            </span>
                          </span>
                          <strong>R$ 1.039,00</strong>
                        </>
                      ) : (
                        formatCurrencyAsText(plan.val_plano_anual)
                      )
                    ) : (
                      formatCurrencyAsText(plan.val_plano_mensal)
                    )}
                  </strong>
                  <small
                    style={{
                      fontWeight: ofertaAtivaPlanPro ? 'bold' : 'normal',
                    }}
                  >
                    {descricaoPlano}
                  </small>
                </div>

                {/* Informação do desconto */}
                {plan.economize > 0 && (
                  <div className="discount-info">Economia de 2 meses</div>
                )}
                {/* Observação anual */}
                {planoPagamento === 'ANUAL' && plan.observationAnual && (
                  <div className="discount-info">{plan.observationAnual}</div>
                )}

                {/* Benefícios */}
                <ul
                  className={`plan-features ${
                    plan.plano === 'Basic' ? 'basic' : ''
                  }`}
                >
                  {plan.content.map((cont) => (
                    <li key={cont}>{cont}</li>
                  ))}
                </ul>

                {/* Botão */}
                <button
                  type="button"
                  className="select-plan-button"
                  style={{
                    background: isPlanoContratado ? '#57069e' : 'white',
                    color: isPlanoContratado ? 'white' : '#57069e',
                    cursor:
                      isPlanoContratado && !isDemonstracao
                        ? 'not-allowed'
                        : 'pointer',
                  }}
                  onClick={() => {
                    if (isPlanoContratado) {
                      if (!isDemonstracao) {
                        toast.success(
                          `Você já possui o plano ${
                            plan.plano
                          } contratado no formato ${planoPagamento.toLowerCase()}.`,
                        );
                        return;
                      }
                    }
                    handlePlanSelected(plan.plano);
                  }}
                >
                  {isDemonstracao
                    ? isPlanoContratado
                      ? 'Efetivar Plano'
                      : 'Selecionar Plano'
                    : isPlanoContratado
                    ? 'Plano Contratado'
                    : 'Selecionar Plano'}
                </button>
              </div>
            );
          })}
        </ContentCard>
      )}
      {screen === 'PLANOS-DISPONIVEIS-DADOS' && (
        <>
          {step === 'DADOS' && (
            <DadosCliente plano={planoSelecionado} tipo={planoPagamento} />
          )}
          {step === 'FATURAMENTO' && (
            <DadosFaturamento plano={planoSelecionado} tipo={planoPagamento} />
          )}
          {step === 'CONCLUIR' && <DadosConcluir />}
          <section className="content-steps">
            {/* {(step === 'FATURAMENTO' && methodFaturamento === 'last-credit-card') && } */}
            {(step === 'CONCLUIR' && methodFaturamento === 'boleto') ||
            (step === 'FINALIZADO' &&
              (methodFaturamento === 'cartao-credito' ||
                methodFaturamento === 'last-credit-card')) ? (
              <CustomButtonNovo
                disabled={false}
                onClick={() => handleScreen('HOME')}
                label="ACESSAR O SISTEMA"
                width="11.875rem"
              />
            ) : (
              <div className="footer-buttons">
                <CustomButtonNovo
                  disabled={showLoading}
                  onClick={onBack}
                  label={step === 'DADOS' ? 'Planos' : 'Voltar'}
                  width="11.875rem"
                  style={{ backgroundColor: '#BF5050' }}
                  outline
                />
                <CustomButtonNovo
                  disabled={
                    (step === 'FATURAMENTO' && !methodFaturamento) ||
                    showLoading
                  }
                  onClick={async () => handleStep('next')}
                  label={
                    stepsOrder[currentStepIndex + 1] === 'FINALIZADO'
                      ? 'PAGAR FATURA'
                      : stepsOrder[currentStepIndex + 1]
                  }
                  width="11.875rem"
                  showLoadingIcon={showLoading}
                />
              </div>
            )}
          </section>
        </>
      )}
    </ContainerPlanosDisponiveis>
  );
};
